<template>
  <v-container class="fill-height justify-center">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <img src="@/assets/billfish-logo-new.png" alt="Billfish" />
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              Reset Password
            </h2>
            <h6 class="subtitle-1 mb-5">Please enter your new password</h6>
            <v-form ref="form" @submit="send">
              <v-text-field
                label="New Password"
                v-model="payload.password"
                :rules="rule.concat(strenghRule)"
                type="password"
                outlined
                clearable
              />
              <v-text-field
                label="Confirm Password"
                v-model="confirmPwd"
                :rules="rule.concat(match)"
                type="password"
                outlined
                clearable
              />
            </v-form>
            <v-btn color="primary" @click="send"> Done </v-btn>
            <v-spacer />
            <v-btn class="mt-5" depressed @click="goBack"> Cancel </v-btn>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <span>
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </span>
              </v-col>
              <v-spacer />
              <v-col>
                <v-list-item to="/privacy" target="_blank" dense>
                  <v-list-item-content v-text="'Privacy'" />
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { notifyMessage } from '../../helpers/handler';
export default {
  name: 'reset-password',
  data: () => ({
    confirmPwd: null,
    payload: {
      email: null,
      password: null,
      token: null
    },
    rule: [(v) => !!v || 'Password is required'],
    strenghRule: [
      (v) =>
        /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/.test(v) ||
        'Password is not strong enough'
    ],
    overlay: false
  }),
  mounted() {
    localStorage.removeItem('tempReset');
    const { token, email } = this.$route?.query;
    if (token && email) {
      localStorage.setItem('tempReset', JSON.stringify({ token, email }));
      this.$router.push({ path: '/reset-password' });
    }
  },
  computed: {
    match() {
      return () =>
        this.payload.password === this.confirmPwd || 'Password Mismatch';
    },
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions('users', ['resetPwd']),
    async send() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        const { token, email } = JSON.parse(localStorage.getItem('tempReset'));
        this.payload.token = token;
        this.payload.email = email;
        const response = await this.resetPwd(this.payload);
        if (response?.data?.success) {
          notifyMessage(response.data.message);
          localStorage.removeItem('tempReset');
          setTimeout(() => {
            this.$router.push({ path: '/login' });
          }, 3000);
        } else notifyMessage('Something went wrong');
        this.overlay = false;
      }
    },
    goBack() {
      this.$router.push({ path: '/login' });
    }
  }
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
